@tailwind base;

@layer base {

  .no-tailwindcss-base h1,
  .no-tailwindcss-base h2,
  .no-tailwindcss-base h3,
  .no-tailwindcss-base h4,
  .no-tailwindcss-base h5,
  .no-tailwindcss-base h6 {
      font-size: revert;
      font-weight: revert;
  }

  .no-tailwindcss-base ol,
  .no-tailwindcss-base ul {
      list-style: revert;
      margin: revert;
      padding: revert;
  }
}

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}